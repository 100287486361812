/*
 * @Author: Andrzej Sulej
 * @Date:   2021-01-26 14:10:10
 * @Last Modified by:   Andrzej Sulej
 * @Last Modified time: 2021-01-26 14:54:16
 */
@import '~antd/dist/antd.css';
@import 'Card.scss';

.App {
  text-align: center;
  background: chartreuse; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Puzzle {
  display:flex;
  flex-wrap: wrap; 
  padding: 2vw;
  align-content: center;
  justify-content: space-around;
  /* height: 100vh; */
  text-align: center;
  background: #80deea;
}