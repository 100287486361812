/*
 * @Author: Andrzej Sulej
 * @Date:   2021-01-26 10:25:34
 * @Last Modified by:   Andrzej Sulej
 * @Last Modified time: 2021-01-26 20:13:57
 */
$hexagon-width: 115px;
$hexagon-height: $hexagon-width*.55;
$hexagon-margin: $hexagon-width/25;
$hexagon-title-color: #fff;
$hexagon-border-color: #fff;
$hexagon-border-color--hover: #72f88e;
$hexagon-image-color: #3ea0eb;
$honeycomb-max-width: 500px;
$viewport-thereshold: $hexagon-width + $hexagon-margin;

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

body {
  font-family: 'Montserrat', sans-serif;
  // background-image: linear-gradient(red, yellow);
  background: #fceddc;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  max-width: $honeycomb-max-width;
  margin: 0 auto;
  padding: 0;
  transform: translateY(#{$hexagon-height/4});

  &-cell {
    flex: 0 1 $hexagon-width;
    max-width: $hexagon-width;
    height: $hexagon-height;
    margin: $hexagon-height/2.1 $hexagon-margin/2 $hexagon-margin;
    position: relative;
    padding: 0.5em;

    text-align: center;
    z-index: 1;

    // &__run {
    //   background: $hexagon-border-color--hover;
    // }

    &__title {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      hyphens: auto;
      word-break: break-word;
      text-transform: uppercase;
      color: #fff;
      font-weight: 700;
      font-size: 1.75em;
      transition: opacity 350ms;
      
      > small {
        font-weight: 300;
        margin-top: 0.25em;
      }
    }

    &__image {
      object-fit: cover;
      object-position: center;
    }
    
    &::before, &::after {
      content: '';
    }

    &::before, &::after, &__image {
      top: -50%;
      left: 0;
      width: 100%;
      height: 200%;
      display: block;
      position: absolute;
      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
      z-index: -1;
    }

    &::before {
      // background-image: linear-gradient(to bottom right,red, yellow);
      // background: $hexagon-border-color;
      transform: scale(1.055);
    }

    &::after {
      background-image: linear-gradient(to bottom right, #cba781, #fbd8ae);
      // background: $hexagon-image-color;
      // opacity: 0.5;
      // transition: opacity 350ms;
    }

       // &:hover {
      
    //   .honeycomb-cell__title {
    //     opacity: 0;
    //   }
      
    //   &::before {
    //     background: $hexagon-border-color--hover;
    //   }

    //   &::after {
    //     opacity: 0;
    //   }
    // }
  }

  &__placeholder{
    // display:none;
    // opacity: 0;
    width: $hexagon-width;
    margin: 0 $hexagon-margin/2;
  }
}

@media (max-width:#{2*$viewport-thereshold}){
  .honeycomb-cell {
    margin: #{$hexagon-margin/2 + $hexagon-height/2} $hexagon-margin;
  }
}

@media (min-width: #{2*$viewport-thereshold}) and (max-width:#{3*$viewport-thereshold}){
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - #{$hexagon-width/2});
    margin-left: calc(50% - #{$hexagon-width/2});
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display:block;
  }
}

@media (min-width: #{3*$viewport-thereshold}) and (max-width:#{4*$viewport-thereshold}) {
  .honeycomb-cell:nth-child(5n + 1) {
    margin-left: calc(50% - #{$hexagon-width + $hexagon-margin});
  }

  .honeycomb-cell:nth-child(5n + 2) {
    margin-right: calc(50% - #{$hexagon-width + $hexagon-margin});
  }

  // .honeycomb__placeholder:nth-child(5n), .honeycomb__placeholder:nth-child(5n + 3) {
  //   display:block;
  // }
}

@media (min-width: #{4*$viewport-thereshold}) {
  .honeycomb-cell:nth-child(7n + 5)  {
    margin-left: calc(50% - #{$hexagon-width*1.5 + $hexagon-margin});
  }

  .honeycomb-cell:nth-child(7n + 7), .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - #{$hexagon-width*1.5 + $hexagon-margin});
  }
  
  .honeycomb-cell:nth-child(12) {
    margin-right: calc(50% - #{$hexagon-width*1.5 + $hexagon-margin});
  }

  // .honeycomb__placeholder:nth-child(7n + 7), .honeycomb__placeholder:nth-child(7n + 9), .honeycomb__placeholder:nth-child(7n + 11){
  //   display:block;
  // }
}